import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import ContactForm from "../components/contactForm";

function StandardPageTemplate({ data }) {
  const { title, body } = data.contentfulPage;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle>{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      <MaxWidthBox>
        <RichText>{renderRichText(body)}</RichText>
      </MaxWidthBox>
      <StyledContactForm />
    </Layout>
  );
}

export default StandardPageTemplate;

export const pageQuery = graphql`
  query ContactPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      body {
        raw
      }
    }
  }
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const StyledContactForm = styled(ContactForm)`
  margin-top: 2rem;
`;
