import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";

const BreadcrumbItem = ({ part, hasSeparator, isInverted }) => {
  const text = part.href ? (
    <Link to={part.href} isInverted={isInverted}>
      {part.name}
    </Link>
  ) : (
    <NotLink>{part.name}</NotLink>
  );

  const separator = hasSeparator ? <Separator /> : null;

  return (
    <Item>
      {text}
      {separator}
    </Item>
  );
};

const Breadcrumb = ({ parts, className, isInverted }) => (
  <BreadcrumbContainer className={className}>
    {parts.map((part, i) => (
      <BreadcrumbItem
        part={part}
        key={part.name + part.href}
        hasSeparator={i < parts.length - 1}
        isInverted={isInverted}
      />
    ))}
  </BreadcrumbContainer>
);

const BreadcrumbContainer = styled.ol`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.75em;
  line-height: 1.4;
`;

const Item = styled.li`
  position: relative;

  display: flex;
  align-items: center;
`;

const Link = styled(GatsbyLink)`
  ${fancyLinkStyles(
    ({ theme, isInverted }) =>
      transparentize(
        0.6,
        isInverted ? theme.palette.black : theme.palette.white
      ),
    ({ theme, isInverted }) =>
      transparentize(
        0.2,
        isInverted ? theme.palette.black : theme.palette.white
      ),
    "0.15em",
    20
  )}

  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-decoration: none;
  color: currentColor;
`;

const NotLink = styled.span``;

const Separator = styled.span`
  &::after {
    content: "/";
    margin: 0 0.25em;
  }
`;

export default Breadcrumb;
