import React, { useRef } from "react";
import styled from "styled-components";
import { graphql, Link as GatsbyLink } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import useHover from "../utils/useHover";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";

function FringeEvent({ pageSlug, slug, title, startDate, startTime }) {
  let down, up;
  let link = useRef();

  const onMouseDown = () => (down = new window.Date().getTime());
  const onMouseUp = () => {
    up = new window.Date().getTime();
    if (up - down < 200) {
      link.current.click();
    }
  };

  const [hoverRef, isHovered] = useHover();
  return (
    <Event
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      ref={hoverRef}
      isHovered={isHovered}
    >
      <Link to={`/${pageSlug}/${slug}/`} ref={link} isHovered={isHovered}>
        {title}
      </Link>
      <div>
        {startDate && <EventStartDate>{startDate}</EventStartDate>}
        {startTime && <EventStartTime>, {startTime}</EventStartTime>}
      </div>
    </Event>
  );
}

function StandardPageTemplate({ data }) {
  const { title, body, childContent = [] } = data.contentfulPage;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs} isInverted={true}>
          <PageTitle>{title}</PageTitle>
        </Hero>
      }
      isInverted={true}
    >
      <SEO title={title} />
      <MaxWidthBox>
        <RichText isInverted>{renderRichText(body)}</RichText>
      </MaxWidthBox>
      {childContent && childContent.length > 0 && (
        <EventList>
          {childContent.map(childContentItem => {
            const {
              id,
              page = null,
              slug,
              title,
              startDate = null,
              startTime = null,
            } = childContentItem;
            const pageSlug = page && page[0].slug;
            return (
              <FringeEvent
                key={id}
                pageSlug={pageSlug}
                slug={slug}
                title={title}
                startDate={startDate}
                startTime={startTime}
              />
            );
          })}
        </EventList>
      )}
    </Layout>
  );
}

export default StandardPageTemplate;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const EventList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  padding-left: 0;
  list-style: none;
`;

const Event = styled.li`
  margin-top: 1rem;
`;

const Link = styled(GatsbyLink)`
  ${fancyLinkStyles(
  ({ theme }) => transparentize(0.6, theme.palette.suffrageGreen),
  ({ theme }) => theme.palette.suffrageGreen,
  "0.15em"
)}

  font-weight: bold;
  text-decoration: none;
`;

const EventStartDate = styled.span``;

const EventStartTime = styled.span``;

export const pageQuery = graphql`
  query FringeEventsPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      body {
        raw
      }
      childContent {
        ... on ContentfulEvent {
          id
          title
          slug
          startDate: startDatetime(formatString: "dddd Do MMMM")
          startTime: startDatetime(formatString: "HH:mm")
          page {
            slug
            id
          }
        }
      }
    }
  }
`;
