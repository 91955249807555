import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <h1>Page not found</h1>
    <p>Sorry, that page doesn&apos;t exist!</p>
  </Layout>
);

export default NotFoundPage;
