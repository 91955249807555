import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { graphql, Link as GatsbyLink } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import { getTrackColor } from "../tokens/colours";
import { Button } from "../components/button";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";
import useHover from "../utils/useHover";

function CollaboratorItem({ pageSlug, slug, name, company, isInverted }) {
  let down, up;
  let link = useRef();

  const onMouseDown = () => (down = new window.Date().getTime());
  const onMouseUp = () => {
    up = new window.Date().getTime();
    if (up - down < 200) {
      link.current.click();
    }
  };

  const [hoverRef, isHovered] = useHover();
  return (
    <Collaborator
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      ref={hoverRef}
      isHovered={isHovered}
    >
      <CollaboratorLink
        to={`/${pageSlug}/${slug}/`}
        ref={link}
        isHovered={isHovered}
        isInverted={isInverted}
      >
        {name}
      </CollaboratorLink>
      {company && <CollaboratorCompany>{company}</CollaboratorCompany>}
    </Collaborator>
  );
}

function EventTemplate({ data }) {
  const {
    isFringe,
    title,
    description,
    startDate,
    endDate,
    startTime,
    endTime,
    registrationInformation = null,
    registrationUrl = null,
    moreInfoUrl = null,
    track = null,
    page = null,
    collaborators = [],
  } = data.contentfulEvent;
  const eventDate =
    startDate === endDate ? startDate : `${startDate} - ${endDate}`;
  const trackAlternativeName = track && track[0].alternativeName;
  const trackNameForCss =
    trackAlternativeName && trackAlternativeName.toLowerCase();

  const middleOfBreadcrumbs = page
    ? [{ name: page[0].title, href: `/${page[0].slug}/` }]
    : [
      {
        name: track[0].page[0].title,
        href: `/${track[0].page[0].slug}/`,
      },
      {
        name: track[0].name,
        href: `/${track[0].page[0].slug}/${track[0].slug}/`,
      },
    ];

  const breadcrumbs = [
    { name: "Home", href: "/" },
    ...middleOfBreadcrumbs,
    { name: title },
  ];
  return (
    <Layout
      hero={
        <Hero
          breadcrumbs={breadcrumbs}
          hasRelativeContainer
          isInverted={!!isFringe}
        >
          {track && (
            <TrackAlternativeName trackName={trackNameForCss}>
              {trackAlternativeName}
            </TrackAlternativeName>
          )}
          <PageTitle>{title}</PageTitle>
        </Hero>
      }
      isInverted={!!isFringe}
    >
      <SEO title={title} />
      <FlexContainer>
        <Column>
          <MaxWidthBox>
            {isFringe && <Date>{eventDate}</Date>}
            <TimeAndTrack>
              <Time>{`${startTime} - ${endTime}`}</Time>{" "}
              {track && <Track>{track[0].name}</Track>}
            </TimeAndTrack>
            <RichText>{renderRichText(description)}</RichText>
          </MaxWidthBox>
        </Column>
        <Column>
          <Meta>
            {registrationInformation && (
              <RegistrationInfo>
                {renderRichText(registrationInformation)}
              </RegistrationInfo>
            )}
            {moreInfoUrl && (
              <Link as="a" href={moreInfoUrl}>
                More information
              </Link>
            )}
            {registrationUrl && (
              <Link as="a" href={registrationUrl}>
                Book online
              </Link>
            )}
          </Meta>
        </Column>
      </FlexContainer>
      {collaborators && (
        <CollaboratorsGrid>
          <h2>
            {isFringe
              ? "Brought to you by"
              : collaborators.length > 1
                ? "Meet our collaborators"
                : "Meet our collaborator"}
          </h2>
          {collaborators.map(collaborator => {
            const {
              id,
              name,
              slug,
              company = null,
              page = null,
            } = collaborator;
            const pageSlug = (page && page[0].slug) || "collaborators";
            return (
              <CollaboratorItem
                key={id}
                pageSlug={pageSlug}
                slug={slug}
                name={name}
                company={company}
                isInverted={!!isFringe}
              />
            );
          })}
        </CollaboratorsGrid>
      )}
    </Layout>
  );
}

export default EventTemplate;

const TrackAlternativeName = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
  margin-left: -4rem;
  padding: 0.5rem 4rem;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${({ trackName }) => getTrackColor(trackName)};
  color: ${({ theme }) => theme.palette.white};

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    bottom: 0;
    left: -50vw;
    right: 0;
    border-left: 50vw solid ${({ trackName }) => getTrackColor(trackName)};
  }

  @media (min-width: 32em) {
    position: absolute;
    top: 3rem;
  }
`;

const Date = styled.div`
  font-weight: bold;
`;

const TimeAndTrack = styled.div`
  font-weight: bold;
`;

const Time = styled.span``;

const Track = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 1em;
  padding-left: 1em;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    display: inline-block;
    height: 1em;
    margin-top: -0.5em;
    border-left: 1px solid currentColor;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;

  @media (min-width: 32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }
`;

const Column = styled.div`
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const MaxWidthBox = styled.div`
  max-width: 65ch;
`;

const Meta = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
`;

const RegistrationInfo = styled.div`
  flex-basis: 100%;
  font-weight: bold;

  & > :first-child {
    margin-top: 0;
  }
`;

const CollaboratorsGrid = styled.ul`
  /* display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr)); */
  margin-top: 2rem;
  padding-left: 0;
  list-style: none;
`;

const Link = styled(Button)`
  white-space: nowrap;
  margin-top: 1rem;
  margin-right: 1rem;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }
`;

const Collaborator = styled.li`
  margin-top: 1rem;
`;

const linkStyles = ({ isInverted = false, theme }) =>
  isInverted
    ? css`
        ${fancyLinkStyles(
      transparentize(0.6, theme.palette.suffrageGreen),
      theme.palette.suffrageGreen,
      "0.15em"
    )}
      `
    : css`
        ${fancyLinkStyles(
      transparentize(0.6, theme.palette.white),
      transparentize(0.2, theme.palette.white),
      "0.15em"
    )}
      `;

const CollaboratorLink = styled(GatsbyLink)`
  ${linkStyles};

  font-weight: bold;
  text-decoration: none;
`;

const CollaboratorCompany = styled.span`
  display: block;
`;

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      isFringe
      title
      description {
        raw
      }
      startDate: startDatetime(formatString: "dddd Do MMMM")
      endDate: endDatetime(formatString: "dddd Do MMMM")
      startTime: startDatetime(formatString: "HH:mm")
      endTime: endDatetime(formatString: "HH:mm")
      track {
        name
        alternativeName
        slug
        page {
          title
          slug
        }
      }
      page {
        title
        slug
      }
      registrationInformation {
        raw
      }
      registrationUrl
      moreInfoUrl
      collaborators {
        ... on ContentfulSpeaker {
          id
          name
          slug
          company
          logo {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          headshot {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          page {
            id
            slug
          }
        }
        ... on ContentfulCollaborator {
          id
          name
          slug
          logo {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          page {
            id
            slug
          }
        }
      }
    }
  }
`;
