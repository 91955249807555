const IWD_BANBURY = {
  palette: {
    adaRed: "#7F3663",
    gretaGreen: "#1B816F",
    cleopatraYellow: "#85713F",
    rosaRed: "#7F364C",
    fridaOrange: "#A26445",
    malalaGreen: "#3F734E",
    ameliaBlue: "#237D99",
    oxfordshireBlue: "#002650",
    white: "#FFF9F3",
    black: "#00162F",
    suffrageGreen: "#0b5526",
    suffragePurple: "#351a5b",
  },
  shadows: {},
};

export const getTrackColor = trackName => {
  switch (trackName) {
    case "ada":
      return IWD_BANBURY.palette.adaRed;
    case "greta":
      return IWD_BANBURY.palette.gretaGreen;
    case "cleopatra":
      return IWD_BANBURY.palette.cleopatraYellow;
    case "rosa":
      return IWD_BANBURY.palette.rosaRed;
    case "frida":
      return IWD_BANBURY.palette.fridaOrange;
    case "malala":
      return IWD_BANBURY.palette.malalaGreen;
    case "amelia":
      return IWD_BANBURY.palette.ameliaBlue;
    default:
      return IWD_BANBURY.palette.oxfordshireBlue;
  }
};

export default IWD_BANBURY;
