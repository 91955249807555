import React from "react";
import { ReactComponent as AdaArtwork } from "../svg/ada.svg";
import { ReactComponent as AmeliaArtwork } from "../svg/amelia.svg";
import { ReactComponent as CleopatraArtwork } from "../svg/cleopatra.svg";
import { ReactComponent as FridaArtwork } from "../svg/frida.svg";
import { ReactComponent as GretaArtwork } from "../svg/greta.svg";
import { ReactComponent as MalalaArtwork } from "../svg/malala.svg";
import { ReactComponent as RosaArtwork } from "../svg/rosa.svg";

const ArtworkSvg = ({ trackName, className }) => {
  switch (trackName) {
    case "ada":
      return <AdaArtwork className={className} />;
    case "amelia":
      return <AmeliaArtwork className={className} />;
    case "cleopatra":
      return <CleopatraArtwork className={className} />;
    case "frida":
      return <FridaArtwork className={className} />;
    case "greta":
      return <GretaArtwork className={className} />;
    case "malala":
      return <MalalaArtwork className={className} />;
    case "rosa":
      return <RosaArtwork className={className} />;
    default:
      return null;
  }
};

export default ArtworkSvg;
