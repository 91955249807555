import styled from "styled-components";
import { focusState } from "../tokens/a11y";

export const Button = styled.button`
  margin: initial;
  padding: initial;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-transform: inherit;
  background-color: transparent;
  color: inherit;

  display: inline-block;
  padding: 0.4em 0.8em;
  border: 1px solid currentColor;
  text-align: center;
  transition: background-color 0.35s, color 0.35s;
  overflow: hidden;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.oxfordshireBlue};
  }

  &:focus {
    ${focusState};
  }
`;
