import React from "react";
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ReactComponent as LogoSvg } from "../svg/logo.svg";
import { Container } from "./container";
import { hideVisually, transparentize } from "polished";
import { fancyLinkStyles } from "../tokens/utils";

const Header = ({ siteTitle }) => {
  const { mainNavigation, additionalNavigation } = useStaticQuery(
    graphql`
      query {
        mainNavigation: contentfulNavigation(slug: { eq: "main-navigation" }) {
          navigationItems {
            ... on ContentfulPage {
              shortTitleForNavigation
              title
              slug
            }
          }
        }
        additionalNavigation: contentfulNavigation(
          slug: { eq: "additional-navigation" }
        ) {
          navigationItems {
            ... on ContentfulPage {
              shortTitleForNavigation
              title
              slug
            }
          }
        }
      }
    `
  );
  const { navigationItems: mainNavigationItems } = mainNavigation;
  const { navigationItems: additionalNavigationItems } = additionalNavigation;
  return (
    <HeaderWrapper>
      <Container>
        <LinksList>
          <LogoLink to="/">
            <Logo />
            <HiddenTitle>{siteTitle}</HiddenTitle>
          </LogoLink>
          <Navigation>
            <NavigationItems>
              {mainNavigationItems.map(page => {
                const { slug, title, shortTitleForNavigation = null } = page;
                return (
                  <NavLink key={slug} to={`/${slug}/`}>
                    <Title>{shortTitleForNavigation || title}</Title>
                  </NavLink>
                );
              })}
            </NavigationItems>
            <AdditionalNavigationItems>
              {additionalNavigationItems.map(page => {
                const { slug, title, shortTitleForNavigation = null } = page;
                return (
                  <NavLink key={slug} to={`/${slug}/`}>
                    <Title>{shortTitleForNavigation || title}</Title>
                  </NavLink>
                );
              })}
            </AdditionalNavigationItems>
          </Navigation>
        </LinksList>
      </Container>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const HeaderWrapper = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.palette.oxfordshireBlue};
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.oxfordshireBlue};
`;

const LogoLink = styled(GatsbyLink)`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.8, theme.palette.suffrageGreen),
    ({ theme }) => theme.palette.suffrageGreen,
    "0.2em"
  )};

  display: block;
  margin: 0 -0.5rem;
  padding: 0.5rem;
`;

const LinksList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Logo = styled(LogoSvg)`
  width: 82px;

  @media (min-width: 32em) {
    width: 156px;
  }
`;

const HiddenTitle = styled.span`
  ${hideVisually};
`;

const Navigation = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const AdditionalNavigationItems = styled.div`
  display: none;

  @media (min-width: 70em) {
    display: flex;
    justify-content: flex-end;
    margin-right: -1rem;
    margin-left: 1rem;
  }
`;

const NavigationItems = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-right: -0.75rem;
  margin-left: 1rem;

  @media (min-width: 32em) {
    margin-right: -1rem;
  }
`;

const NavLink = styled(GatsbyLink)`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.8, theme.palette.suffrageGreen),
    ({ theme }) => theme.palette.suffrageGreen,
    "0.2em"
  )};

  position: relative;
  display: flex;
  padding: 0.5rem 0.75rem;
  text-decoration: none;

  @media (min-width: 32em) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

const Title = styled.span`
  position: relative;
  align-self: center;
`;

export default Header;
