import styled, { css } from "styled-components";
import IWD_BANBURY from "./colours";
import { transparentize, hideVisually } from "polished";

export const focusState = css`
  box-shadow: 0 0 0.125rem 0.125rem
    ${transparentize(0.25, IWD_BANBURY.palette.white)};
  outline: none;
`;

export const HideVisually = styled.span`
  ${hideVisually};
`;
