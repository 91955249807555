import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";

function StandardPageTemplate({ data }) {
  const { title, body, childContent = [] } = data.contentfulPage;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle>{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      <MaxWidthBox>
        <RichText>{renderRichText(body)}</RichText>
      </MaxWidthBox>
      {childContent.length > 0 && (
        <CollaboratorList>
          {childContent.map(childContentItem => (
            <CollaboratorListItem key={childContentItem.id}>
              <CollaboratorListItemLink
                to={`/${childContentItem.page[0].slug}/${childContentItem.slug}/`}
              >
                {childContentItem.name}
              </CollaboratorListItemLink>
            </CollaboratorListItem>
          ))}
        </CollaboratorList>
      )}
    </Layout>
  );
}

export default StandardPageTemplate;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const CollaboratorList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding-left: 0;
  list-style: none;
`;

const CollaboratorListItem = styled.li`
  margin-top: 1rem;
  margin-right: 1.5rem;
`;

const CollaboratorListItemLink = styled(Link)`
  ${fancyLinkStyles(
  ({ theme }) => transparentize(0.6, theme.palette.white),
  ({ theme }) => transparentize(0.2, theme.palette.white),
  "0.15em"
)}

  text-decoration: none;
`;

export const pageQuery = graphql`
  query CollaboratorsPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      body {
        raw
      }
      childContent {
        ... on ContentfulCollaborator {
          id
          name
          slug
          page {
            slug
            id
          }
        }
        ... on ContentfulSpeaker {
          id
          name
          slug
          page {
            slug
            id
          }
        }
      }
    }
  }
`;
