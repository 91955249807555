/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled, {
  css,
  createGlobalStyle,
  ThemeProvider,
} from "styled-components";
import "typeface-lato";
import "typeface-playfair-display";
import IWD_BANBURY from "../tokens/colours";
import Header from "./header";
import Footer from "./footer";
import { Container } from "./container";
import { bodyCopy } from "../tokens/typography";

const Layout = ({ children, hero = null, isInverted = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={IWD_BANBURY}>
      <GlobalStyle />
      <LayoutWrapper isInverted={isInverted}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>
          {hero}
          <Container>{children}</Container>
        </Main>
        <Footer />
      </LayoutWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    font-size: 18px;
    line-height: 1.5;
    overflow: hidden;

    @media (min-width: 32em) {
      font-size: 24px;
    }
  }

  body {
    ${bodyCopy};
    height: 100%;
    margin: 0;
    overflow-x: hidden;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  svg {
    display: block;
    fill: currentColor;
  }

  a {
    color: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  dl {
    margin: 1em 0 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }
`;

const invertedColors = ({ isInverted }) =>
  isInverted
    ? css`
        background-color: ${({ theme }) => theme.palette.white};
        color: ${({ theme }) => theme.palette.oxfordshireBlue};
      `
    : css`
        background-color: ${({ theme }) => theme.palette.oxfordshireBlue};
        color: ${({ theme }) => theme.palette.white};
      `;

const LayoutWrapper = styled.div`
  ${invertedColors};

  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Main = styled.main`
  position: relative;
  flex-grow: 1;
  padding-bottom: 4rem;
  z-index: 1;
`;

export default Layout;
