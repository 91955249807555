import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import SocialIcon from "../components/socialIcon";
import { hideVisually } from "polished";

function CuratorTemplate({ data }) {
  const {
    name,
    bio,
    url,
    socialMedia = [],
    logoOnDark = null,
    page,
  } = data.contentfulCurator;
  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: page[0].title, href: `/${page[0].slug}/` },
    { name: name },
  ];
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle>{name}</PageTitle>
        </Hero>
      }
    >
      <SEO title={name} />
      <FlexContainer>
        {logoOnDark && (
          <Col1>
            <Logo fluid={logoOnDark.fluid} />
          </Col1>
        )}

        <Col2>
          <MaxWidthBox>
            <RichText>{renderRichText(bio)}</RichText>
          </MaxWidthBox>
          <SocialList>
            <SocialListItem>
              <SocialListItemLink href={url}>
                <Icon type="website" />
                <HiddenLabel>{name} website</HiddenLabel>
              </SocialListItemLink>
            </SocialListItem>
            {socialMedia.length > 0 &&
              socialMedia.map(socialMediaItem => {
                const {
                  id,
                  type: typeCaptialised,
                  name,
                  url,
                } = socialMediaItem;
                const type = typeCaptialised.toLowerCase();
                return (
                  <SocialListItem key={id}>
                    <SocialListItemLink href={url}>
                      <Icon type={type} />
                      <HiddenLabel>{name}</HiddenLabel>
                    </SocialListItemLink>
                  </SocialListItem>
                );
              })}
          </SocialList>
        </Col2>
      </FlexContainer>
    </Layout>
  );
}

export default CuratorTemplate;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-right: -1rem;
  margin-left: -1rem;
`;

const Col1 = styled.div`
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
`;

const Col2 = styled.div`
  margin-top: -1rem;
  padding-right: 1rem;
  padding-left: 1rem;
`;

const Logo = styled(GatsbyImage)`
  width: 12em;
  max-width: 100%;
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const SocialList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding-left: 0;
  list-style: none;
`;

const SocialListItem = styled.li`
  margin-top: 1rem;
  margin-right: 1rem;
`;

const SocialListItemLink = styled.a`
  display: block;
  padding: 0.5rem;
`;

const Icon = styled(SocialIcon)`
  width: 2em;
  height: 2em;
`;

const HiddenLabel = styled.span`
  ${hideVisually}
`;

export const pageQuery = graphql`
  query CuratorBySlug($slug: String!) {
    contentfulCurator(slug: { eq: $slug }) {
      name
      bio {
        raw
      }
      url
      logoOnDark {
        fluid(maxWidth: 500) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      socialMedia {
        id
        type
        name
        url
      }
      page {
        slug
        title
      }
    }
  }
`;
