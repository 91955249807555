import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import ContactForm from "../components/contactForm";

const ContactPage = () => {
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: "Contact us" }];
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle>Contact us</PageTitle>
        </Hero>
      }
    >
      <SEO title="Contact us" />
      <ContactForm />
    </Layout>
  );
};

export default ContactPage;
