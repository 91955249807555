import React, { useRef } from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import GatsbyImage from "gatsby-image";
import useHover from "../utils/useHover";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";

export default function Speaker({ pageSlug, slug, headshot, name, company }) {
  let down, up;
  let link = useRef();

  const onMouseDown = () => (down = new window.Date().getTime());
  const onMouseUp = () => {
    up = new window.Date().getTime();
    if (up - down < 200) {
      link.current.click();
    }
  };

  const [hoverRef, isHovered] = useHover();
  return (
    <SpeakersListItem
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      ref={hoverRef}
      isHovered={isHovered}
    >
      {headshot && <Headshot fluid={headshot.fluid} />}
      <SpeakerInfo>
        <SpeakersListItemLink
          to={`/${pageSlug}/${slug}/`}
          ref={link}
          isHovered={isHovered}
        >
          <SpeakerName>{name}</SpeakerName>
        </SpeakersListItemLink>
        <SpeakerCompany>{company}</SpeakerCompany>
      </SpeakerInfo>
    </SpeakersListItem>
  );
}

const SpeakersListItem = styled.li`
  display: flex;
  width: 12rem;
  margin-top: 1rem;
  margin-right: 1rem;
`;

const SpeakersListItemLink = styled(GatsbyLink)`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.6, theme.palette.white),
    ({ theme }) => transparentize(0.2, theme.palette.white),
    "0.15em"
  )}

  text-decoration: none;
`;

const Headshot = styled(GatsbyImage)`
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  filter: grayscale(100%);
`;

const SpeakerInfo = styled.div`
  margin-left: 1rem;
  font-size: 0.8em;
`;

const SpeakerName = styled.span`
  font-weight: bold;
`;

const SpeakerCompany = styled.span`
  display: block;
`;
