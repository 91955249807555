import { css } from "styled-components";

export const latoStack =
  'Lato, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export const playfairStack = '"Playfair Display", Times New Roman, serif';

export const siteTitle = css`
  font-family: ${playfairStack};
  font-size: 2.3em;
  font-weight: 900;
  line-height: 1.1;

  @media (min-width: 32em) {
    font-size: 2.7em;
  }

  @media (min-width: 42em) {
    font-size: 3.4em;
  }
`;

export const pageTitle = css`
  font-family: ${playfairStack};
  font-size: 2.3em;
  font-weight: 900;
  line-height: 1.1;

  @media (min-width: 32em) {
    font-size: 2.6em;
  }

  @media (min-width: 42em) {
    font-size: 3em;
  }
`;

export const sectionHeading = css`
  position: relative;
  margin-top: 1.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid currentColor;
  font-family: ${playfairStack};
  font-weight: normal;
  font-size: 2em;
  line-height: 1.2;

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    bottom: -1px;
    left: -50vw;
    right: 0;
    border-left: 50vw solid currentColor;
  }
`;

export const subHeading = css`
  font-family: ${latoStack};
  font-weight: bold;
  font-size: 1.8em;
  line-height: 1.2;
`;

export const bodyCopy = css`
  font-family: ${latoStack};
  font-weight: 300;
  line-height: 1.5;
  /* 
  @media (min-width: 32em) {
    font-size: calc(0.9375em + 0.1875 * (100vw - 20em) / (62 - 32));
  }

  @media (min-width: 62em) {
    font-size: calc(0.9375rem + 0.1875 * 1em);
  } */
`;
