import React from "react";
import styled, { css } from "styled-components";
import { Container } from "./container";
import Breadcrumb from "./breadcrumb";

const Hero = ({
  children,
  breadcrumbs = null,
  hasRelativeContainer = false,
  isInverted = false,
}) => (
  <Wrapper>
    <Container>
      {breadcrumbs && (
        <Breadcrumb isInverted={isInverted} parts={breadcrumbs} />
      )}
      <ChildrenWrapper hasRelativeContainer={hasRelativeContainer}>
        {children}
      </ChildrenWrapper>
    </Container>
  </Wrapper>
);

export default Hero;

const Wrapper = styled.div`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const isPositionRelative = ({ hasRelativeContainer }) =>
  hasRelativeContainer &&
  css`
    position: relative;
  `;

const ChildrenWrapper = styled.div`
  ${isPositionRelative};
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: 32em) {
    padding-top: 10rem;
  }
`;
