import styled from "styled-components";
import { pageTitle, siteTitle } from "../tokens/typography";
import { getTrackColor } from "../tokens/colours";

export const SiteTitle = styled.h1`
  ${siteTitle};
`;

export const PageTitle = styled.h1`
  ${pageTitle};

  max-width: 10em;
  margin-top: 0;
`;

export const ThemedPageTitle = styled.h1`
  ${pageTitle};

  position: relative;
  display: inline-block;
  margin-top: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: calc(100% - 1rem);
    right: 2rem;
    bottom: -0.5rem;
    left: -1rem;
    background-color: ${({ trackName }) => getTrackColor(trackName)};
    z-index: -1;
  }
`;
