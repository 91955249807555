import React, { useEffect } from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { getTrackColor } from "../tokens/colours";
import { subHeading } from "../tokens/typography";

const ThemedBox = ({
  trackNameForCss,
  name,
  alternativeName,
  descriptiveTitle,
  intro,
  events,
  startDatetime = null,
  endDatetime = null,
  moreInfoLink = null,
}) => {
  let down, up;
  let link = React.createRef();
  let card = React.createRef();

  const onMouseDown = () => (down = new window.Date().getTime());
  const onMouseUp = () => {
    up = new window.Date().getTime();
    if (up - down < 200) {
      link.current.click();
    }
  };

  useEffect(() => {
    card.current.style.cursor = "pointer";
  });
  return (
    <Box
      trackName={trackNameForCss}
      ref={card}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <MaxWidthBox>
        <AlternativeName trackName={trackNameForCss}>
          {alternativeName}
        </AlternativeName>
        <DescriptiveTitle>{descriptiveTitle}</DescriptiveTitle>
        {startDatetime && (
          <Time>
            {startDatetime}
            {endDatetime && ` - ${endDatetime}`}
          </Time>
        )}
        <Name>{name}</Name>
      </MaxWidthBox>
      <FlexContainer>
        <Col1>
          <MaxWidthBox>
            {intro && <Intro>{intro}</Intro>}
            {events &&
              events.map(event => {
                const { id, title, startDatetime, endDatetime } = event;
                return (
                  <Agenda key={id}>
                    <AgendaTime>
                      {startDatetime} - {endDatetime}
                    </AgendaTime>
                    <AgendaDescription>{title}</AgendaDescription>
                  </Agenda>
                );
              })}
          </MaxWidthBox>
        </Col1>
        <Col2>
          {moreInfoLink && (
            <Link to={moreInfoLink} ref={link}>
              More information
            </Link>
          )}
        </Col2>
      </FlexContainer>
    </Box>
  );
};

export default ThemedBox;

const Box = styled.div`
  position: relative;
  margin-right: -1rem;
  padding: 1rem;
  font-weight: normal;
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.black};

  @media (min-width: 32em) {
    margin-right: -2rem;
    padding: 1rem 2rem 2rem 2rem;
  }

  & + & {
    margin-top: 6rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 5rem;
    right: 1rem;
    bottom: -1rem;
    left: -1rem;
    z-index: -1;
    background-color: ${({ trackName }) => getTrackColor(trackName)};

    @media (min-width: 32em) {
      right: 2rem;
      bottom: -2rem;
      left: -2rem;
    }
  }
`;

const AlternativeName = styled.div`
  display: inline-block;
  margin-left: -4rem;
  padding: 0.5rem 4rem;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${({ trackName }) => getTrackColor(trackName)};
  color: ${({ theme }) => theme.palette.white};
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const DescriptiveTitle = styled.h2`
  ${subHeading};
  margin-top: 1rem;
`;

const Time = styled.span`
  display: inline-block;
  margin-top: 1rem;
`;

const Name = styled.span`
  display: block;
  margin-top: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-right: -1rem;
  margin-left: -1rem;

  @media (min-width: 32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }
`;

const Col1 = styled.div`
  flex-grow: 1;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const Col2 = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const Intro = styled.div`
  margin-top: 1rem;
  font-size: 1.2em;
`;

const Agenda = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-right: -1rem;
  margin-left: -1rem;
`;

const AgendaTime = styled.dt`
  flex-shrink: 0;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 32em) {
    width: 12rem;
  }
`;

const AgendaDescription = styled.dd`
  margin-left: 0;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 32em) {
    flex-basis: 50%;
    flex-grow: 1;
  }
`;

const Link = styled(GatsbyLink)`
  display: inline-block;
  white-space: nowrap;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid currentColor;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }
`;
