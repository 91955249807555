import React, { useRef } from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { transparentize } from "polished";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getTrackColor } from "../tokens/colours";
import ArtworkSvg from "../components/artworkSvg";
import { ReactComponent as ArrowIcon } from "../svg/arrow.svg";
import { fancyLinkStyles } from "../tokens/utils";
import useHover from "../utils/useHover";

const HomePageTrack = ({
  trackNameForCss,
  alternativeName,
  descriptiveTitle,
  trackName,
  shortDescription = null,
  page = null,
  slug,
}) => {
  let down, up;
  let link = useRef();

  const onMouseDown = () => (down = new window.Date().getTime());
  const onMouseUp = () => {
    up = new window.Date().getTime();
    if (up - down < 200) {
      link.current.click();
    }
  };

  const [hoverRef, isHovered] = useHover();

  return (
    <TrackWrapper>
      <Track
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        ref={hoverRef}
        isHovered={isHovered}
      >
        <Artwork trackName={trackNameForCss} />
        <AlternativeName trackName={trackNameForCss}>
          {alternativeName}
        </AlternativeName>
        <DescriptiveTitle>
          <Link
            to={`/${page[0].slug}/${slug}/`}
            ref={link}
            trackName={trackNameForCss}
            isHovered={isHovered}
          >
            {descriptiveTitle}{" "}
            <Arrow aria-hidden="true" isHovered={isHovered} />
          </Link>
        </DescriptiveTitle>
        <TrackName>{trackName}</TrackName>
        <MaxWidthBox>
          {shortDescription && renderRichText(shortDescription)}
        </MaxWidthBox>
      </Track>
    </TrackWrapper>
  );
};

export default HomePageTrack;

const TrackWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Track = styled.div`
  display: inline-block;
  margin-top: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: normal;
`;

const Artwork = styled(ArtworkSvg)`
  position: absolute;
  right: 0;
  z-index: -1;

  max-width: 60%;
  max-height: 90%;
  fill: none !important;
  stroke: ${({ theme }) => theme.palette.white};
  stroke-width: 2;

  @media (min-width: 62em) {
    top: 5%;
    max-width: 30%;
    max-height: 100%;
  }
`;

const AlternativeName = styled.div`
  position: relative;
  display: inline-block;
  margin-left: -1rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${({ trackName }) => getTrackColor(trackName)};
  color: ${({ theme }) => theme.palette.white};

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    bottom: 0;
    left: -50vw;
    right: 0;
    border-left: 50vw solid ${({ trackName }) => getTrackColor(trackName)};
  }
`;

const DescriptiveTitle = styled.h3`
  position: relative;
  margin-top: 1rem;
  font-size: 1.5em;
`;

const Link = styled(GatsbyLink)`
  position: relative;
  text-decoration: none;

  ${fancyLinkStyles(
  ({ trackName }) => transparentize(0.5, getTrackColor(trackName)),
  ({ trackName }) => getTrackColor(trackName)
)}
`;

const TrackName = styled.span`
  display: inline-block;
  margin-top: 0.5rem;
  text-transform: uppercase;
`;

const MaxWidthBox = styled.div`
  max-width: 50ch;
`;

const Arrow = styled(ArrowIcon)`
  display: inline-block;
  margin-left: 0.5em;
  height: 1rem;
  transform: ${({ isHovered }) =>
    isHovered ? "translateX(0)" : "translateX(-0.2em)"};
  transition: transform 0.2s ease-in-out;

  ${Link}:hover &,
  ${Link}:focus & {
    transform: translateX(0);
  }
`;
